import React from "react";
import Index from "./components";
import "./theme.scss";
import "./assets/css/themify-icons.css";
import "./assets/css/style.css";

export default function App() {
    return (
      <>
          <Index/>
      </>
    );
}
